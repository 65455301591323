import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Меню піцерії Bella Vita
			</title>
			<meta name={"description"} content={"Побалуйте себе мистецтвом італійської кухні"} />
			<meta property={"og:title"} content={"Меню піцерії Bella Vita"} />
			<meta property={"og:description"} content={"Побалуйте себе мистецтвом італійської кухні"} />
			<meta property={"og:image"} content={"https://cryptoluxstar.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cryptoluxstar.com/img/01345534.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cryptoluxstar.com/img/01345534.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cryptoluxstar.com/img/01345534.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cryptoluxstar.com/img/01345534.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cryptoluxstar.com/img/01345534.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cryptoluxstar.com/img/01345534.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-3">
			<Box display="flex" flex-direction="row" padding="0px 0px 50px 0px">
				<Box
					display="flex"
					width="20%"
					flex-direction="column"
					justify-content="center"
					align-items="flex-start"
					lg-align-items="center"
					lg-margin="0px 0px 20px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="16px 16px 16px 0px"
					lg-width="100%"
					md-margin="0px 0px 20px 0px"
					sm-margin="0px 0px 30px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--dark"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
						padding="0px 16px 0px 0px"
					>
						Меню
					</Text>
				</Box>
				<Box
					display="flex"
					width="75%"
					flex-direction="column"
					justify-content="flex-start"
					align-items="flex-start"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="28px 0px 16px 16px"
					lg-width="100%"
					md-margin="0px 0px 20px 0px"
					sm-margin="0px 0px 0px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--darkL1"
						font="--lead"
						lg-text-align="center"
						display="flex"
						align-items="flex-start"
					>
						У піцерії Bella Vita ми пропонуємо різноманітні страви, які демонструють багаті кулінарні традиції Італії. Ось деякі з найпопулярніших страв з нашого меню:
					</Text>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cryptoluxstar.com/img/4.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Фірмові піци
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Маргарита: Класична улюблена страва з помідорами Сан-Марцано, свіжою моцарелою та базиліком.
					<br/>
Quattro Formaggi: Багате поєднання моцарели, горгонзоли, пармезану та сиру фонтіна.
					<br/>
Пепероні Делайт: Щедрі порції гострої пепероні на подушці з моцарели та томатного соусу.
					<br/>
Veggie Supreme: барвиста суміш болгарського перцю, цибулі, грибів та оливок.
					<br/>
Прошутто з руколою: тонко нарізаний прошутто, приправлений свіжою руколою та нарізаним пармезаном.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cryptoluxstar.com/img/5.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Закуски
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Брускетта: підсмажений хліб зі свіжими помідорами та базиліком.
					<br/>
Салат Капрезе: Шматочки свіжої моцарели, помідори та базилік, политі бальзамічним соусом.
					<br/>
Часникові вузлики: М'які, маслянисті вузлики з тіста, змащені часником і петрушкою.
					<br/>
Аранчіні: Хрусткі рисові кульки з начинкою з моцарели та соусом маринара.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cryptoluxstar.com/img/6.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Салати
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Салат "Цезар": Хрусткий салат ромен, сухарики та пармезан, заправлені вершковим соусом "Цезар".
					<br/>
Середземноморський салат: Мікс зелені, бринза, оливки, огірки та лимонний вінегрет.
					<br/>
Панзанелла: Традиційний італійський хлібний салат з помідорами, огірками та червоною цибулею.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-3">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cryptoluxstar.com/img/7.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Десерти
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Тірамісу: Класичний італійський десерт з шарами просочених кавою тістечок та кремом з маскарпоне.
					<br/>
Каннолі: Хрусткі оболонки з тіста, наповнені солодким кремом з рікотти та шоколадною крихтою.
					<br/>
Панна Котта: Вершково-ванільний заварний крем зі свіжими ягодами.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://cryptoluxstar.com/img/8.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Напої
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Італійські газовані напої: освіжаючі газовані напої з різноманітними фруктовими смаками.
					<br/>
					Еспресо та капучино: Насичена, ароматна кава, зварена до досконалості.
					<br/>
					Домашні вина: Добірка червоних та білих вин, які доповнять вашу трапезу.
					</Text>
				</Box>
			</Box>
		</Section>

		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				width="40%"
				align-items="flex-start"
			>
				<Box
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 60% 0px"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						width="100%"
						right={0}
						min-height="100%"
						top="auto"
						left={0}
						bottom="0px"
						src="https://cryptoluxstar.com/img/9.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				flex-direction="row"
				flex-wrap="wrap"
				width="60%"
				lg-width="100%"
				lg-order="-1"
			>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="100%"
					flex-direction="column"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="8px 16px 0px 16px"
				>
					<Text
						lg-text-align="center"
						sm-font="--headline3"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--headline2"
						md-text-align="left"
					>
						Ознайомтеся з повним меню
					</Text>
				</Box>
				<Box
					align-items="flex-start"
					lg-width="100%"
					lg-align-items="center"
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					padding="16px 16px 16px 16px"
					display="flex"
					sm-margin="0px 0px 0px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						color="--grey"
						font="--base"
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						md-text-align="left"
					>
						Це лише короткий огляд того, що ми можемо запропонувати. Наше повне меню включає різноманітні страви на будь-який смак та дієтичні вподобання. Якщо у вас є якісь дієтичні проблеми або особливі побажання, будь ласка, звертайтеся до нас. 
					</Text>
				</Box>
				<Box
					lg-margin="0px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					padding="16px 16px 16px 16px"
					display="flex"
					lg-align-items="center"
					align-items="flex-start"
					lg-width="100%"
					sm-margin="0px 0px 0px 0px"
					width="50%"
					flex-direction="column"
					lg-padding="0px 16px 16px 16px"
				>
					<Text
						lg-text-align="center"
						sm-margin="0px 0px 30px 0px"
						margin="0px 0px 0px 0px"
						color="--grey"
						font="--base"
						md-text-align="left"
					>
						Наш персонал завжди готовий допомогти вам і забезпечити виняткові враження від відвідування ресторану. Щоб дізнатися більше про наші пропозиції або зарезервувати столик, будь ласка, зв'яжіться з нами. Завітайте до нас у піцерію Bella Vita і дозвольте нам провести вас у кулінарну подорож Італією.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});